//CSS
require('../css/mega-menu/mega_menu.css');
require('../css/bootstrap.css');
require('../css/owl.css');
require('../css/shortcodes.css');
require('../css/style.css');
require('../css/responsive.css');
require('ionicons/css/ionicons.css');
require( "Cookieconsent/build/cookieconsent.min.css" );


//JS
require('jarallax/dist/jarallax.js');
require('jquery-sticky/jquery.sticky.js');
require('./owl.js');
require('./main.js');
require('ua-parser-js');


