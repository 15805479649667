"use strict";
let data = {
    'name' : "name",
    'mail' : "mail",
    'telefon' : "phone",
    'message' : "message"
}  

/*************************
Page loader
*************************/
function preloader() {
    $("#load").fadeOut();
    $('#loading').delay().fadeOut();
}

/*************************
Jarallax
*************************/
function parallax() {
    var a = new UAParser();
    var name = a.getResult().browser.name;
    let jarallaxStatus;

    if (name === "Edge" || name === "IE") {
        jarallaxStatus = false;
    } else{
        jarallaxStatus = true;
    }
    if (jarallaxStatus === true) {
        jarallax(document.querySelectorAll('.jarallax'), {
            speed: 0.2
        });        
    }
    if (jarallaxStatus === false && name === "Edge") {
        document.getElementById('iq-home').classList.add('fixedPics');
        document.getElementById('pic2').classList.add('fixedPics');
        document.getElementById('pic3').classList.add('fixedPics');
        document.getElementById('pills-tabContent').classList.add('fixedPics');   
    }
}

/*************************
Firebase Config
*************************/
var firebaseConfig = {
    apiKey: "AIzaSyD_8Y1s5ij68qiL0OUkXSjIq8KY116J77U",
    authDomain: "tac-solutions.firebaseapp.com",
    databaseURL: "https://tac-solutions.firebaseio.com",
    projectId: "tac-solutions",
    storageBucket: "",
    messagingSenderId: "609003389572",
    appId: "1:609003389572:web:b89cd69f12f90ada"
};
// Initialize Firebase
var firebase = require('firebase/app');
firebase.initializeApp(firebaseConfig);

/*************************
Cookie Consent
*************************/

require( "CookieConsent" );
let regex1 = new RegExp("cookieconsent_status=allow");
let regex2 = new RegExp("cookieconsent_status=deny");
function cooki() {
    if(regex1.test(document.cookie) === true && regex2.test(document.cookie) === false) {
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'UA-146676901-1');
    } else if(regex1.test(document.cookie) === false && regex2.test(document.cookie) === true){
    } else{
        window.cookieconsent.initialise({
            content: {
                message: "Wir verwenden Cookies, um Dir den Besuch auf unserer Website möglichst angenehm zu gestalten. Beim Weitersurfen stimmst Du der Verwendung von Cookies zu.",
                deny: "Ablehnen",
                allow: "Ok",
                link: "Mehr Infos",
                href: "../../Impressum.html"
            },
            palette:{
                popup: {
                    background: "#222222",
                    text: "#ffffff"
                },
                button: {
                    background: "transparent",
                    text: "#ffffff",
                    border: "#1181b2"
                }
                
            },
            revokable:true,
            onStatusChange: function(status) {
                if(this.hasConsented() === true){
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', 'UA-146676901-1');
                } 
            },
            type: "opt-in",      
            popup: true,
            secure: true
        });
    }
}

/*************************
Back to top
*************************/
function backtotop() {
    $('#back-to-top').fadeOut();
    $(window).scroll(function () {
        if ($(this).scrollTop() > 690) {
            $('#back-to-top').fadeIn(1500);
        } else {
            $('#back-to-top').fadeOut(690);
        }
    });
    // scroll body to 0px on click
    $('#top').on('click', function () {
        $('body,html').animate({
            scrollTop: 0
        }, 800);
        return false;
    });
}

/*************************
  smooth scroll
*************************/
function smoothscroll() {
    $(document).on('click', 'a[href^="#"]', function (event) {
        if ($(window).width() >= 751) {
            event.preventDefault();
            $('html, body').on("scroll mousedown wheel DOMMouseScroll mousewheel keyup touchmove", function(){
                $('html, body').stop();
            });
            $('html, body').stop(true, false)
            $('html, body').animate({
                scrollTop: $($.attr(this, 'href')).offset().top - 70
            }, 1000 , function(){
                $('html, body').off("scroll mousedown wheel DOMMouseScroll mousewheel keyup touchmove");
            });
        } else{
            $('html, body').stop(true, false)
            $('html, body').animate({
                scrollTop: $($.attr(this, 'href')).offset().top - 70
            }, 0);
        }
        
    });

    

}

/*************************
owl carousel 
*************************/
function owlcarousel() {
    $(".owl-carousel").each(function () {
        var $this = $(this),
            $items = ($this.data('items')) ? $this.data('items') : 1,
            $loop = ($this.data('loop')) ? $this.data('loop') : true,
            $navdots = ($this.data('nav-dots')) ? $this.data('nav-dots') : false,
            $navarrow = ($this.data('nav-arrow')) ? $this.data('nav-arrow') : false,
            $autoplay = ($this.attr('data-autoplay')) ? $this.data('autoplay') : true,
            $space = ($this.attr('data-space')) ? $this.data('space') : 15;
        $(this).owlCarousel({
            loop: $loop,
            items: $items,
            responsive: {
                0: {
                    items: $this.data('xx-items') ? $this.data('xx-items') : 1
                },
                600: {
                    items: $this.data('xs-items') ? $this.data('xs-items') : 1
                },
                767: {
                    items: $this.data('sm-items') ? $this.data('sm-items') : 2
                },
                992: {
                    items: $this.data('md-items') ? $this.data('md-items') : 2
                },
                1190: {
                    items: $this.data('lg-items') ? $this.data('lg-items') : 3
                },
                1200: {
                    items: $items
                }
            },
            dots: $navdots,
            margin: $space,
            nav: $navarrow,
            navText: ["<i aria-hidden="+"true"+" class="+"ion-chevron-left iq-font-green"+"></i>", "<i aria-hidden="+"true"+" class="+"ion-chevron-right iq-font-green"+"></i>"],
            autoplay: $autoplay,
            autoplayHoverPause: true
        });

    });

}

/*************************
reCAPTCHA
*************************/
let captchaCheck = false;
function recaptchaCallback(test) {  
    if (test) {
        captchaCheck = true;
    }    
    
    data.hash = test;
    return test;
}
//Initialzie function
window.recaptchaCallback = recaptchaCallback;


/*************************
ValidateEmail
*************************/
function ValidateEmail(mail) {

    var re = /^(?:[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;
    return re.test(mail);
}

/*************************
checkName
*************************/
function checkName(inputtxt){
    if (inputtxt.match(/[0-9]/)) {
        return false;
    }else{
        return true;
    }
    
}  

/*************************
checkNumber
*************************/
function checkNumber(inputtxt){
    if (inputtxt === "") {
        return true;
    } else{
        if (inputtxt.match(/[a-z]/i)) {
            return false;
        }else{
            return true;
        }
    }
    
    
}  

/*************************
sendFormular
*************************/
function sendFormular(){   

    $('#submit').click(() =>{

        var server = 'https://tac-solutions.firebaseio.com/messages.json';    
        var name = document.getElementById('contactform')['name'].value;
        var mail = document.getElementById('contactform')['mail'].value;
        var phone = document.getElementById('contactform')['phone'].value;
        var message = document.getElementById('contactform')['message'].value;
        var errorMessage = document.getElementById('errorMessage');
        var success = document.getElementById('success');
        var agb = document.getElementById('agb');        
        var form = document.getElementById('contactform');

        data.name = name;
        data.mail = mail;
        data.telefon = phone;
        data.message = message;
        let captchaStatus;
        if (captchaCheck === true ) {
            captchaStatus = true;
        } else{
            errorMessage.innerText = 'Überprüfe das reCaptcha!'
        }
        let nameStatus;
        if (name !== '' && checkName(name) === true) {
            nameStatus = true;
            document.getElementById('name').style.borderColor = "#e0e0e0";
        } else{
            document.getElementById('name').style.border = "1px solid red"
            errorMessage.innerText = 'Überprüfe den rot makierten Bereich!'
        }
        let mailStatus;
        if (mail !== '' && ValidateEmail(mail) === true) {
            mailStatus = true;
            document.getElementById('mail').style.borderColor = "#e0e0e0";
        } else{
            document.getElementById('mail').style.border = "1px solid red"
            errorMessage.innerText = 'Überprüfe den rot makierten Bereich!'
        }
        let phoneStatus;
        if (checkNumber(phone) === true) {
            document.getElementById('phone').style.borderColor = "#e0e0e0";
            phoneStatus = true;
        } else{
            document.getElementById('phone').style.border = "1px solid red"
            errorMessage.innerText = 'Überprüfe den rot makierten Bereich!'
        }
        let messageStatus;
        if (message !== '') {
            document.getElementById('message').style.borderColor = "#e0e0e0";
            messageStatus = true;
        } else{
            document.getElementById('message').style.border = "1px solid red"
            errorMessage.innerText = 'Überprüfe den rot makierten Bereich!'
        }
        let agbStatus;
        if (agb.checked === true) {
            document.getElementById('agbField').style.borderColor = "#e0e0e0";
            agbStatus = true;
        } else{
            document.getElementById('agbField').style.border = "1px solid red"
            errorMessage.innerText = 'Überprüfe den rot makierten Bereich!'
        }
        if (captchaStatus === true && nameStatus === true && mailStatus === true && phoneStatus === true && messageStatus === true && agbStatus === true) {
            
            fetch(server , {
                method: 'POST', // or 'PUT'
                body: JSON.stringify(data), 
                headers: {"Content-Type": "application/json",},
            }).then(function (){
                errorMessage.innerText = "";
                form.style.display = 'none';
                success.style.display = 'block';
                document.getElementById('Pflichtfelder').style.display = "none";
           
            });
        }
    })  
}

/*************************
openHeader
*************************/
let clickStatus;
clickStatus = false;
function openHeader() {    
    $(window).click(function(e) {
        if (e.target.id === 'navbarButton') {
            if (document.body.clientWidth >= 993) {
                return;
            }
            if (clickStatus === false) {
                $('#menu').css({
                    'display' : 'block'
                });      
                $('#navbarButton').removeClass('ion-android-menu');
                $('#navbarButton').addClass('ion-android-close');
                clickStatus = true;
                return;
            }else if (clickStatus === true) {           
                $('#menu').css({
                    'display' : 'none'
                });
                $('#navbarButton').removeClass('ion-android-close');
                $('#navbarButton').addClass('ion-android-menu');
                clickStatus = false;
                return;
            }
        } else {
            if (clickStatus === true) {
                document.getElementById('menu').style.display = 'none';        
                $('#navbarButton').removeClass('ion-android-close');
                $('#navbarButton').addClass('ion-android-menu');
                clickStatus = false;
            } else {
                return;
            }
        }
        
        
    })

    
}





/*************************
All function are called here 
*************************/
$(document).ready(function () {
    cooki(),
    parallax(),
    openHeader(),
    sendFormular(),
    smoothscroll(),
    owlcarousel(),
    backtotop(),
    //JQ-Functions stickyJS
    $("#iq-header").sticky({
        topSpacing:0,
        zIndex: 999
    });
    
});

$(window).on('load', function () {
    preloader(),
    $('body,html').animate({
        scrollTop: 0
    }, 10);
    
});